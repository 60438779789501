import { Image } from "@mantine/core"
import { IconVersions } from "@tabler/icons-react"
import { useEffect, useState } from "react"

export const ProjectIcon = ({ project }) => {
  const [icon, setIcon] = useState()

  useEffect(() => {
    let _url = new URL(
      project.sitemap.indexOf("http") === 0
        ? project.sitemap
        : "http://" + project.sitemap
    )
    if (_url.hostname) {
      const url =
        "https://www.google.com/s2/favicons?domain=" + _url.hostname + "&sz=128"
      setIcon({ src: url })
    }
  }, [project.sitemap])

  return icon ? (
    <Image alt={project.sitemap} height={"100%"} src={icon.src} />
  ) : (
    <IconVersions className={"rotate270deg"} size="1.5rem" stroke={1.5} />
  )
}
export default ProjectIcon
