const apiURL = import.meta.env.VITE_API_URL

export const getClient = (session, clientUUID = undefined) => {
  if (!session || !session.clients?.length) {
    return session?.client
  }
  if (!clientUUID) {
    clientUUID = session.me?.meta?.clientUUID
  }
  session.client = session.clients.find((c) => c.clientUUID === clientUUID)
  if (!session.client) {
    session.client = session.clients[0]
  }
  // if (!session.client) {
  //   throw 'FIXME: session.client is missing'
  // }
  return session.client
}

export const getClientByString = (session, clientUUID = undefined) => {
  try {
    return getClient(session && JSON.parse(session), clientUUID)
  } catch (e) {
    console.error(e)
  }
}

export const getClientSession = (clientUUID = undefined) => {
  const session = localStorage.getItem("user", { defaultValue: false })
  return session ? getClientByString(session, clientUUID) : undefined
}

export const getSessions = () => {
  const session = localStorage.getItem("user", { defaultValue: false })
  return session ? getClientByString(session, clientUUID) : undefined
}

export const getApiClientURL = (clientUUID = undefined) => {
  const session = getClientSession(clientUUID)
  if (!session) {
    throw new Error("no session")
  }
  return `${apiURL}/clients/${session.clientUUID}`
}
