import { ProjectContext } from "@lib/projectProvider"
import { getClientSession } from "@lib/session"
import { useProject } from "@lib/useProjectHook"
import {
  Alert,
  Box,
  Button,
  Group,
  NativeSelect,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { strings } from "../../locales/locale_en"

export const NewProject = (props) => {
  const { onClose = null } = props
  const navigate = useNavigate()
  const { createProject, loadingProject, projectError } = useProject()
  const [user] = useLocalStorage({ key: "user" })
  const projects = useContext(ProjectContext)

  const [project] = useState({
    crawler: "website",
    projectName: "",
    startscan: true,
    testmode: false,
  })

  const onCancel = () => {
    modals.closeAll()
    if (onClose) onClose()
  }

  const form = useForm({
    initialValues: { ...project },
    validate: {
      projectName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      //email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      sitemap: (value, values) => {
        if (values.crawler === "website") {
          if (
            !/^(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-z]{2,}(?:\.[a-z]{2,3}\/?)?$/.test(
              value
            )
          ) {
            return "Invalid Domain"
          }

          return null
        }
        if (values.crawler === "sitemap") {
          if (!/[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?\/sitemap.xml$/.test(value)) {
            return "Invalid sitemap url"
          }

          return null
          // TODO: check if sitemap is valid
          // const res = axios.get(value);
          // return res.status === 200 ? null : 'Invalid sitemap url'
        }
      },
    },
  })

  const submitForm = async (values) => {
    values.startscan = !!user?.me.meta.requireVerify
    createProject({
      form: values,
    }).then((res) => {
      if (res.projectID) {
        modals.closeAll()
        projects.loadProjects()
        if (onClose) onClose(res)
        navigate(`/projects/${res.projectID}`)
      }
    })
  }

  return (
    <Stack gap={"sm"} mt={"lg"}>
      {projects.projects.length >=
        (getClientSession()?.meta.maxProjects || 0) && (
        <Group>
          <Title order={5}>
            You have reached the maximum number of domains in your plan. Please
            consider upgrading your plan to add more domains.
          </Title>
          <Button
            onClick={() => {
              modals.closeAll()
              navigate("/settings/subscription")
            }}
          >
            Go to subscription settings
          </Button>
        </Group>
      )}

      {projects.projects.length <
        (getClientSession()?.meta.maxProjects || 0) && (
        <Stack>
          <form
            onSubmit={form.onSubmit((values) => submitForm(values))}
            style={{
              root: {
                "& label": {
                  fontSize: "1.4rem",
                },
              },
            }}
          >
            <Box mb={"sm"}>
              <TextInput
                label={"Project name"}
                maxLength={120}
                placeholder="My project"
                {...form.getInputProps("projectName")}
                description={""}
              />
            </Box>

            <Box mb={"sm"}>
              <NativeSelect
                data={[
                  {
                    label: "Crawl Website",
                    value: "website",
                  },
                  {
                    label: "Sitemap.xml",
                    value: "sitemap",
                  },
                ]}
                label={"Scanning method"}
                mt={"md"}
                //defaultValue={form.getInputProps('crawler')}
                {...form.getInputProps("crawler")}
              />
              {form.values.crawler === "website" && (
                <Text size={"sm"}>
                  The scan will follow all internal links of that domain.
                </Text>
              )}
              {form.values.crawler === "sitemap" && (
                <Text size={"sm"}>
                  Scans the sitemap.xml and scans all sites listed in it.
                </Text>
              )}
            </Box>

            <Box mb={"sm"}>
              <TextInput
                label={
                  form.getInputProps("crawler").value === "sitemap"
                    ? "Sitemap URL"
                    : "Main Domain"
                }
                maxLength={100}
                mt="md"
                placeholder="https://example.com"
                {...form.getInputProps("sitemap")}
              />
              {form.values.crawler === "website" && (
                <Text className={"description"} size={"sm"}>
                  Please enter the sites domain name (https://domain.com) and
                  not addresses to single pages (like
                  https://domain.com/pages/contact) to allow the crawler to scan
                  all sites on the domain.
                </Text>
              )}
              {form.values.crawler === "sitemap" && (
                <Text size={"sm"}>
                  The URL to a valid sitemap.xml file, such as
                  http://mydomain.com/sitemap.xml
                </Text>
              )}
            </Box>

            <Group>
              {projectError && projectError.msg === "http_302" && (
                <Alert>
                  The domain is permanently being redirected to:{" "}
                  <b>{projectError.location}</b>. This leads to inconsistent
                  scans. Consider using Sitemap crawling instead.
                </Alert>
              )}

              {projectError && (
                <Alert color={"red"} mt={"md"} w={"100%"}>
                  {strings[projectError.msg + "_long"]
                    ? strings[projectError.msg + "_long"]
                    : strings[projectError.msg]
                      ? strings[projectError.msg]
                      : " An error occured with message:" + projectError.msg}
                </Alert>
              )}

              <Button disabled={loadingProject} mt="md" type="submit">
                Add Domain
              </Button>

              <Button mt="md" onClick={onCancel} variant={"outline"}>
                Cancel
              </Button>
            </Group>
          </form>
        </Stack>
      )}
    </Stack>
  )
}

export default NewProject
