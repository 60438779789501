import { Flex, Text } from "@mantine/core"
import { IconLoader3 } from "@tabler/icons-react"

const LoadingStatus = ({ p, ...props }) => {
  return (
    <Flex align={"center"} direction={"row"} p={p}>
      <IconLoader3 className={"spin"} stroke={1} {...props} />{" "}
      {props.label && (
        <Text ml={"10px"} size={props.size || "md"}>
          {props.label}
        </Text>
      )}
    </Flex>
  )
}

export default LoadingStatus
