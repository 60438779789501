import { useLocalStorage, useTimeout } from "@mantine/hooks"
import _isEqual from "lodash/isEqual"
import { createContext, useCallback, useEffect, useState } from "react"

import { getApiClientURL } from "./session.js"
import { useOggy } from "./useOggyHook.js"

export const ProjectContext = createContext(undefined, undefined)

export const ProjectProvider = ({ children }) => {
  // Define state to store the selected project and other data
  // Load projects and data here (you can use useEffect or any other method)
  const { getApiAdapter } = useOggy()

  const [loadingProjects, setLoadingProjects] = useState(true)
  const [projects, setProjects] = useLocalStorage({
    defaultValue: [],
    key: "ogifyProjects",
  })

  const [projectsError, setProjectsError] = useState(false)
  const session = JSON.parse(localStorage.getItem("user")) || false

  const statusTimout = useTimeout(() => {
    loadProjectStatuses().then((r) => console.info(r))
  }, 10000)

  useEffect(() => {
    return () => {
      if (statusTimout.clear) statusTimout.clear()
    }
  }, [statusTimout])

  const loadProjectStatuses = useCallback(() => {
    return getApiAdapter()
      .post(`${getApiClientURL()}/statuses`)
      .then((res) => {
        if (projects) {
          let _projects = [...projects]
          Object.keys(res.data.statuses).map((id) => {
            projects.map((project) => {
              if (parseInt(project.projectID) === parseInt(id)) {
                project.status = res.data.statuses[id]
              }
            })
          })

          if (!_isEqual(_projects, projects)) {
            setProjects(projects)
          }
        }
      })
  }, [])

  const loadProjects = useCallback((silent = false) => {
    // if we are already waiting, skip
    if (!silent) {
      setLoadingProjects(true)
    }
    return getApiAdapter()
      .get(`${getApiClientURL()}/projects`)
      .then((projects) => {
        setProjects(projects.data.projects)
        setLoadingProjects(false)
        loadProjectStatuses().then((r) => r)
        return projects.data.projects
      })
      .catch((err) => {
        setProjectsError(err)
      })
  }, [])

  // let's load all projects as soon as we have a valid session
  useEffect(() => {
    if (session) {
      loadProjects()
      loadProjectStatuses()
    }
  }, [])

  const setProject = (projecID, data) => {
    setProjects(
      projects.map((project) => {
        if (project.projectID === projecID) {
          project = { ...project, ...data }
        }
        return project
      })
    )
  }

  const setProjectInProjects = (project) => {
    setProjects(
      projects.map((p) => {
        if (p.projectID === project.projectID) {
          p = project
        }
        return p
      })
    )
  }

  return (
    <ProjectContext.Provider
      value={{
        loadProjects,
        loadingProjects,
        projects,
        projectsError,
        setProject,
        setProjectInProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
