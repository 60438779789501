import { useLocalStorage } from "@mantine/hooks"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

const ProtectedRoutes = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem("ogify-token")
  const user = JSON.parse(localStorage.getItem("user"))

  // why? to utilise cross browser tab synchronization
  const [_token] = useLocalStorage({ key: "ogify-token" })

  useEffect(() => {
    if (!token || !user) {
      navigate(`/auth/login?r=${window.location.pathname}`)
    }
  }, [navigate, token, _token, user])

  return <Outlet />
}
export default ProtectedRoutes
