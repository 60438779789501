import { Suspense, lazy } from "react"
import { MantineProvider } from "@mantine/core"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import * as Sentry from "@sentry/react"
import "@mantine/core/styles.css"
import "./index.css"

import ProtectedRoutes from "@components/ProtectedRoutes.jsx"
import Layout from "./components/Layout/Layout"
import LoadingStatus from "./components/LoadingStatus"
import PagesLayout from "./routes/pages/Layout"
import Auth from "./routes/auth/Auth"

Sentry.init({
  dsn: "https://afdf89e29ef453344e571a624587401a@o4507389331177472.ingest.de.sentry.io/4507389334650960",
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/\*\.ogify\.io\/api/],
})

const Invite = lazy(() => import("./routes/invite/Invite"))
const ResetPW = lazy(() => import("./routes/auth/RequestPasswordReset"))
const SignIn = lazy(() => import("./routes/auth/SignIn"))
const SignUp = lazy(() => import("./routes/auth/SignUp"))
const Verify = lazy(() => import("./routes/auth/Verify"))
const Help = lazy(() => import("./routes/help/Help"))
const NewProject = lazy(() => import("./routes/project/NewProject"))
const Reset = lazy(() => import("./routes/reset/Reset"))
const Home = lazy(() => import("./routes/home/Home"))
const Project = lazy(() => import("./routes/project/Project"))
const Settings = lazy(() => import("./routes/settings"))
const SiteLoader = lazy(() => import("./routes/site/SiteLoader"))
const Site = lazy(() => import("./routes/site/Site"))
const Privacy = lazy(() => import("./routes/pages/Privacy"))
const TOS = lazy(() => import("./routes/pages/TOS"))
const Contact = lazy(() => import("./routes/pages/Contact"))
const Feedback = lazy(() => import("./routes/pages/Feedback"))
const Worksheet = lazy(() => import("./routes/worksheets/Worksheet"))
const Worksheets = lazy(() => import("./routes/worksheets/index"))
const CrawlerPreview = lazy(() => import("./routes/pages/CrawlerPreview"))
const { theme } = import("./theme.js")

const Suspended = (Component) => (
  <Suspense fallback={<LoadingStatus />}>
    <Component />
  </Suspense>
)

export default function App() {
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route element={<PagesLayout />} path="/pages">
            <Route element={Suspended(TOS)} path="tos" />
            <Route element={Suspended(Privacy)} path="privacy" />
            <Route element={Suspended(Contact)} path="contact" />
            <Route element={Suspended(Feedback)} path="feedback" />
            <Route
              element={Suspended(CrawlerPreview)}
              path="crawler/:projectId?/:pageuri?"
            />
          </Route>
          <Route element={Suspended(Reset)} path="/reset" />
          <Route element={Suspended(Invite)} path="/invites/:inviteToken" />
          <Route element={<Auth />} path="/auth">
            <Route element={Suspended(SignIn)} path="login" />
            <Route element={Suspended(Verify)} path="verify/:uuid" />
            <Route element={Suspended(SignUp)} path="signup" />
            <Route element={Suspended(ResetPW)} path="resetpassword/:uuid?" />
          </Route>

          <Route element={<Layout />} path="/">
            <Route element={<ProtectedRoutes />}>
              <Route element={Suspended(Home)} index path="/:firstRun?" />
              <Route element={Suspended(Project)} path="/projects/:id" />
              <Route
                element={Suspended(SiteLoader)}
                path="/projects/:projectID/pages/:pageID"
              />
              <Route element={Suspended(Site)} path="/pages/:id" />
              <Route
                element={Suspended(Worksheet)}
                path="/worksheets/:sheet/:site?"
              />
              <Route element={Suspended(Worksheets)} path="/worksheets" />
              <Route element={Suspended(NewProject)} path="/firstProject" />
              <Route element={Suspended(Settings)} path="/settings/:view?" />
              <Route element={Suspended(Help)} path="/help" />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  )
}
