import { useInterval } from "@mantine/hooks"
import { useCallback, useEffect, useState } from "react"

import SiteScoreWorker from "./../lib/calculateSiteScore.webworker.js?worker"
import { getApiClientURL } from "./session.js"
import { useOggy } from "./useOggyHook.js"
const mySiteScoreWorker = new SiteScoreWorker()

export const useProject = (id) => {
  const { getApiAdapter } = useOggy()
  const [project, setProject] = useState(false)
  const [projectError, setProjectError] = useState(false)
  const [loadingProject, setLoadingProject] = useState(false)
  const [status, setStatus] = useState()
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [projectsError, setProjectsError] = useState(false)
  const [projects, setProjects] = useState(false)
  const [scans, setScans] = useState([])
  const [scan, setScan] = useState()
  const [loadingScan, setLoadingScan] = useState(false)
  const [sites, setSites] = useState()
  const [scanError, setScanError] = useState(false)
  const [siteScore, setSiteScore] = useState(false)
  const [myAbortControllers, setMyAbortControllers] = useState([])

  function newAbortSignal(timeoutMs = 60000) {
    const abortController = new AbortController()
    setTimeout(() => abortController.abort(), timeoutMs || 0)
    myAbortControllers.push(abortController)
    setMyAbortControllers(myAbortControllers)
    return abortController.signal
  }

  function cancelAllRequests() {
    for (const abortController of myAbortControllers) {
      abortController.abort()
    }
  }

  const loadProject = useCallback(
    (silent = false) => {
      setLoadingProject(true)
      setProjectError(false)

      if (!id) {
        return false
      }

      if (!silent) {
        setProject(false)
        setScans([])
        setSites()
      }

      getApiAdapter()
        .get(`${getApiClientURL()}/projects/${id}`, {
          signal: newAbortSignal(),
        })
        .then((res) => {
          if (res) {
            setProject(res.data.project)
            setScans(res.data.scans)
            setLoadingProject(false)
          }
        })
        .catch((_err) => {
          return false
        })
    },
    [id]
  )

  const loadStatusTimeout = useInterval(() => {
    loadScan()
    loadStatus(id)
  }, 3000)

  useEffect(() => {
    loadStatusTimeout.stop()

    if (myAbortControllers.length > 0) {
      cancelAllRequests()
    }

    if (id) {
      loadProject()
      loadScan()
      loadStatus()
    }
  }, [id])

  const loadStatus = () => {
    getApiAdapter()
      .post(`${getApiClientURL()}/projects/${id}/status`, {
        signal: newAbortSignal(30000),
      })

      .then((res) => {
        if (res.data) {
          if (res.data.status?.finished) {
            loadStatusTimeout.stop()
            loadProject(project.projectID, true)
          }

          setStatus(res.data.status)
          return res.data.status
        }
      })
      .catch((err) => {
        console.log("Error", err)
        setProjectError(err)
      })
  }

  const startScan = () => {
    setStatus({
      count: {
        ready: 0,
        wait: 0,
      },
      processing: true,
    })

    setScanError(false)
    setSites([])

    getApiAdapter()
      .post(`${getApiClientURL()}/projects/${project.projectID}/crawler`, {})
      .then((res) => {
        if (res.data.success) {
          loadStatusTimeout.start()
          setSites([])
          return res.data
        } else {
          setScanError(res.data.msg)
          return res.data
        }
      })
      .catch((err) => {
        if (err.msg === "me_notverify") {
          setScanError(
            "Please verify your email address first by clicking the verify link in the email we sent you."
          )
        }
        if (err.msg === "db_project_processing") {
          setScanError("A different scan job is currently processing.")
        }
      })
  }

  const cancelScan = () => {
    loadStatusTimeout.stop()
    setStatus(false)
    cancelAllRequests()
    return getApiAdapter().post(
      `${getApiClientURL()}/projects/${project.projectID}/stop`,
      {}
    )
  }

  const loadScan = (scanID = "$last") => {
    setScanError(false)

    return getApiAdapter()
      .get(`${getApiClientURL()}/projects/${id}/scans/${scanID}`, {
        signal: newAbortSignal(),
      })
      .then((res) => {
        if (res.data) {
          res.data.scan.sites = res.data.sites
          setScan(res.data.scan)
          setLoadingScan(false)

          mySiteScoreWorker.onmessage = (e) => {
            setSites(e.data.sites)
            setSiteScore(e.data.siteScore)
          }

          mySiteScoreWorker.postMessage({ sites: [...res.data.sites] })
        }
      })
      .catch((err) => {
        console.log(err)

        setLoadingScan(false)
        //setScanError(err)
        return false
      })
  }

  const createProject = async (values) => {
    values.check = false
    setLoadingProject(true)

    return getApiAdapter()
      .post(`${getApiClientURL()}/projects`, values)
      .then((res) => {
        if (!res.data.success) {
          setProjectError(res.data.msg)

          return false
        }

        if (values.startscan) {
          startScan(res.data.projectID)
        }
        return res.data.project
      })
      .catch((err) => {
        setProjectError(err)
      })
      .finally(() => {
        setLoadingProject(false)
      })
  }

  const updateProject = async (data) => {
    setProjectError(false)

    return getApiAdapter()
      .put(`${getApiClientURL()}/projects/${data.form.projectID}`, data)
      .then((res) => {
        if (res.data.success) {
          setProject({ ...res.data.project })
        } else {
          setProjectError(res.msg)
        }
        return res.data.project
      })
      .catch((err) => {
        setProjectError(err)
        return err
      })
      .finally(() => {
        setLoadingProject(false)
      })
  }

  const deleteProject = async (id) => {
    //setLoading(true);
    return await getApiAdapter()
      .delete(`${getApiClientURL()}/projects/${id}`)
      .then((res) => {
        if (!res.status) {
          if (res.msg === "http_302") {
            setProjectError(res.msg)
          }
          return
        }

        if (res.status === 200) {
          //Todo: Remove Project from State
          //removeProject(id);
        }
      })
      .catch((err) => {
        setProjectError(err)
        return err
      })
      .finally(() => {
        setLoadingProject(false)
      })
  }

  return {
    cancelScan,
    createProject,
    deleteProject,
    loadProject,
    loadScan,
    loadStatus,
    loadingProject,
    loadingProjects,
    loadingScan,
    project,
    projectError,
    projects,
    projectsError,
    scan,
    scanError,
    scans,
    siteScore,
    sites,
    startScan,
    status,
    updateProject,
  }
}
