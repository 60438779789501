import { AppShell, NavLink } from "@mantine/core"
import { IconHome2 } from "@tabler/icons-react"
import { Outlet, useNavigate } from "react-router-dom"

export const PagesLayout = () => {
  const navigate = useNavigate()
  return (
    <AppShell
      navbar={
        <AppShell.Navbar p="xs" width={{ base: 200 }}>
          <NavLink
            icon={<IconHome2 size="1rem" stroke={1.5} />}
            label="Privacy Policy"
            onClick={() => navigate("/pages/privacy")}
          />
          <NavLink
            icon={<IconHome2 size="1rem" stroke={1.5} />}
            label=" Terms of Service"
            onClick={() => navigate("/pages/tos")}
          />
          <NavLink
            icon={<IconHome2 size="1rem" stroke={1.5} />}
            label="Contact"
            onClick={() => navigate("/pages/contact")}
          />
          <NavLink
            icon={<IconHome2 size="1rem" stroke={1.5} />}
            label="Feedback"
            onClick={() => navigate("/pages/feedback")}
          />
          <NavLink
            icon={<IconHome2 size="1rem" stroke={1.5} />}
            label="Back"
            onClick={() => navigate("/")}
          />
        </AppShell.Navbar>
      }
      padding={"xs"}
    >
      <Outlet />
    </AppShell>
  )
}

export default PagesLayout
