import { Group, Image, Stack, Text } from "@mantine/core"
import { Outlet } from "react-router-dom"

import logo from "../../assets/logo_square.png"
import classes from "./Auth.module.css"
export default function Auth() {
  return (
    <Stack maw={400} mt="5em" mx="auto" p={"sm"}>
      <Group>
        <Image className={classes.logo} mb={"10px"} src={logo} w={80} />
        <Text mb={"30px"} size={"xl"}>
          The ultimate OpenGraph Analyser
        </Text>
      </Group>
      <Outlet />
    </Stack>
  )
}
