import { ActionIcon, Menu } from "@mantine/core"
import { IconUsersGroup } from "@tabler/icons-react"

export const ClientSwitcher = () => {
  const session = JSON.parse(localStorage.getItem("user") || "{}")
  if (!session.clients) {
    return false
  }
  const selectClient = (clientUUID) => {
    const session = JSON.parse(localStorage.getItem("user") || "{}")
    console.log(session.clients)
    session.client = session.clients.find((c) => c.clientUUID === clientUUID)
    localStorage.setItem("user", JSON.stringify(session))
  }

  return (
    <Menu shadow="md" width={"300px"} withArrow={true}>
      <Menu.Target>
        <ActionIcon
          color="gray"
          h={"38px"}
          size="lg"
          title="Switch team"
          variant={"light"}
          w={"40px"}
        >
          <IconUsersGroup size={25} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Switch to one of your teams:</Menu.Label>
        {session.clients.map((e) => (
          <Menu.Item
            key={e.clientUUID}
            onCLick={() => selectClient(e.clientUUID)}
          >
            {e.clientName}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
