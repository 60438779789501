import { ProjectProvider } from "@lib/projectProvider.jsx"
import { AppShell, Burger } from "@mantine/core"
import { useDisclosure, useLocalStorage } from "@mantine/hooks"
import { ModalsProvider } from "@mantine/modals"
import { Outlet } from "react-router-dom"

import { LayoutNavbar } from "./Layout.Navbar.jsx"

export default function Layout() {
  const [token] = useLocalStorage({
    defaultValue: false,
    key: "ogify-token",
  })
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure()
  const [desktopOpened] = useDisclosure(true)
  const [collapsed] = useLocalStorage({
    defaultValue: false,
    key: "navbar-collapsed",
  })

  return (
    <ProjectProvider>
      <ModalsProvider>
        <AppShell
          disabled={!token}
          navbar={{
            breakpoint: "xs",
            collapsed: { desktop: !desktopOpened, mobile: !mobileOpened },
            width: collapsed ? 50 : 300,
          }}
          padding={"xs"}
        >
          {token && (
            <>
              <Burger
                hiddenFrom="sm"
                m={"sm"}
                onClick={toggleMobile}
                opened={mobileOpened}
                size="md"
              />
            </>
          )}
          <LayoutNavbar
            setShowNavbar={toggleMobile}
            showNavbar={mobileOpened}
          />
          <AppShell.Main>
            <Outlet />
          </AppShell.Main>
        </AppShell>
      </ModalsProvider>
    </ProjectProvider>
  )
}
